// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/install_iphone1.jpg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/install_iphone2.jpg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/install_iphone3.jpg", import.meta.url);
// Module
var code = `<template>
    <require from="./iphone.scss"></require>
    <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
    <section id="install_iphone" class="flex-column">
      <div id="header" class="flex-row">
        <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
        <h1 i18n="home.welcome.install_iphone1"></h1>
      </div>
      <div class="step_1 flex-column" if.bind="step == 1">
        <p i18n="home.welcome.install_iphone_step1">You must do this stuff</p>
        <img src="${___HTML_LOADER_IMPORT_1___}">
      </div>
      <div class="step_2 flex-column" if.bind="step == 2">
        <p i18n="home.welcome.install_iphone_step2">And then you do this</p>
        <img src="${___HTML_LOADER_IMPORT_2___}">
      </div>
      <div class="step_3 flex-column" if.bind="step == 3">
        <p i18n="home.welcome.install_iphone_step3">Last step : do this stuff</p>
        <img src="${___HTML_LOADER_IMPORT_3___}">
      </div>
      <div class="step_4 flex-column" if.bind="step == 4">
        <p i18n="home.welcome.install_iphone_step4"></p>
        <div class="flex-grow"></div>
        <animated-checkmark></animated-checkmark>
        <div class="flex-grow"></div>
      </div>
      <div class="flex-grow"></div>
      <button class="btn btn-primary" click.delegate="goToNextImage()" if.bind ="step < 4">
        <span t="home.next"></span>
      </button>
      <button class="btn btn-secondary" click.delegate="goToPreviousImage()" if.bind ="step > 1">
        <span t="home.back"></span>
      </button>
      <div class="spacer-default" if.bind ="step <= 1"></div>
    </section>
  </template>
  `;
// Exports
export default code;